@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';

.q-card {
    background: $app-color-background;
}

.plash {
    position: absolute;
    width: calc(100% - 32px);
    z-index: 9;
}

.q-btn {
    width: calc(50% - 8px) !important;
}

